* {
  font-size: 18px;
}
.wrapper {
  margin: 40px;
  max-height: 1%;
}

p {
  margin-right: 15px;
}

em {
  margin-right: 15px;
}
strong {
  margin-right: 15px;
}
button {
  padding: 10px;
  margin-top: 16.5px;
  margin-left: 40px;
  border: none;
  background-color: rgb(5, 84, 255);
  border-radius: 4px;
  font-size: 14px;
  color: rgb(233, 228, 228);
}

button:hover{
  background-color: rgb(22, 6, 167);
  cursor: pointer;
}
button:active {
  transform: scale(0.95);
}